import React, { Fragment } from "react";
import ConsultationForm from "../../components/Consultation/ConsultationForm";
import SEO from "../../layout/Seo";

export default () => (
  <Fragment>
    <SEO title="Add New Consultation" />
    <ConsultationForm type="add" />
  </Fragment>
);
